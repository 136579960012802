import { useEffect, useRef, useCallback, useState } from 'react';
import { styled } from '@stitches/react';
import { canUseDOM } from 'Shared/DOM/WindowHelper';
import PrimaryButton from 'DesignComponents/Atoms/Buttons/PrimaryButton';
import Button from 'Shared/Components/Buttons/Button';
import SecondaryButton from 'DesignComponents/Atoms/Buttons/SecondaryButton';
import Heading from 'DesignSystem/Typography/Headings/Heading';
import XHtmlArea from 'DesignComponents/Molecules/XHtmlArea/XHtmlArea';

type Props = {
  IsOpen: boolean;
  CancelButtonText: string;
  ActionButtonText: string;
  onCancelButtonClick: () => void;
  onActionButtonClick: () => void;
  popupHeading: string;
  popupBodyText: string;
};

const PromptPopup = ({
  IsOpen,
  CancelButtonText,
  ActionButtonText,
  onActionButtonClick,
  onCancelButtonClick,
  popupHeading,
  popupBodyText,
}: Props) => {
  const [isOpenPopup, setIsOpenPopup] = useState(IsOpen);
  const popupRef = useRef<HTMLDivElement | null>(null);
  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      closePopup();
    }
  }, []);

  useEffect(() => {
    setIsOpenPopup(IsOpen);
  }, [IsOpen]);

  useEffect(() => {
    if (canUseDOM()) {
      if (isOpenPopup) {
        document.body.style.overflow = 'hidden';

        window.addEventListener('keydown', handleKeyDown);

        if (popupRef.current) {
          popupRef.current.focus();
        }
      } else {
        document.body.style.overflow = 'auto';
      }

      return () => {
        window.removeEventListener('keydown', handleKeyDown);
        document.body.style.overflow = 'auto';
      };
    }
  }, [isOpenPopup, handleKeyDown]);

  const closePopup = () => {
    setIsOpenPopup(false);
    onCancelButtonClick();
  };

  return isOpenPopup ? (
    <Overlay>
      <PopupWrapper>
        <PopupContainer
          role="dialog"
          aria-modal="true"
          aria-labelledby="popup-message"
          tabIndex={-1}
          ref={popupRef}
        >
          <PopupContent id="popup-message">
            <Heading
              tag="h2"
              size="l"
              weights="semiBold"
              css={{
                mb: 4,
                fontSize: '$fontSize500',
                lineHeight: '$lh24',
              }}
            >
              {popupHeading}
            </Heading>
            <XHtmlArea content={popupBodyText as string} />
          </PopupContent>
          <PopupButtons>
            <Button<typeof SecondaryButton>
              element="SecondaryButton"
              props={{
                text: CancelButtonText,
                color: 'Regular',
                hug: 'width',
              }}
              onClick={closePopup}
            />

            <Button<typeof PrimaryButton>
              element="PrimaryButton"
              props={{
                text: ActionButtonText,
                color: 'Regular',
                hug: 'width',
              }}
              onClick={onActionButtonClick}
            />
          </PopupButtons>
        </PopupContainer>
      </PopupWrapper>
    </Overlay>
  ) : null;
};

const Overlay = styled('div', {
  position: 'fixed',
  inset: 0,
  zIndex: '$ModalOverlay',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const PopupWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  overflowY: 'auto',
  width: '100%',
  maxHeight: 'calc(100vh - 20px)',
  padding: '$s625',
});

const PopupContainer = styled('div', {
  backgroundColor: '$surface',
  maxWidth: '640px',
  width: '100%',
  fontSize: '$fontSize200',
  lineHeight: '$lh24',
  color: '$onSurface',
  borderRadius: 'rExtraSmall',
  '@media (max-width: 720px)': {
    fontSize: '$fontSize100',
  },
});

const PopupContent = styled('div', {
  padding: '$s200 $s200 $s475 $s200',
  '@media (max-width: 720px)': {
    padding: '$s150 $s150 $s300 $s150',
  },
});

const PopupButtons = styled('div', {
  display: 'flex',
  gap: '$s50',
  flexWrap: 'wrap',
  fontSize: '$fontSize75',
  textTransform: 'uppercase',
  padding: '$s150 $s200',
  borderTop: '1px solid $onSearch',
  wordBreak: 'break-word',
  '& button': {
    flex: '1 1 200px',
    lineHeight: '$lh20',
    display: 'flex',
    alignItems: 'stretch',
  },
  '@media (max-width: 720px)': {
    padding: '$s150',
  },
});

export default PromptPopup;
