import { API_ROOT_PATH } from 'Shared/Constants/route';
import { addScrollHistory } from 'Kex/KexRouter/ScrollHandling';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { GetCart } from 'Commerce/Cart/Cart';

export const SET_SHIPPINGDESTINATION_URL =
  API_ROOT_PATH + '/shipment/SetShippingDestination';

export const SetShippingDestination = async (
  countryCode: string | undefined,
  reloadPage = true
) => {
  if (!countryCode) {
    return;
  }

  try {
    const response = await fetch(SET_SHIPPINGDESTINATION_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `countryCode=${countryCode}`,
    });

    if (!response.ok) {
      throw new Error('Failed to save shipping destination');
    }

    if (reloadPage) {
      // Save the current scroll position
      addScrollHistory();
      // Reload the page to reflect changes
      location.reload();
    }
  } catch (error) {
    console.error(error);
  }
};

export const SwitchShippingDestination = async (
  countryCode: string | undefined
) => {
  try {
    const response = await fetch(
      API_ROOT_PATH + '/shipment/SwitchShippingDestination',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `countryCode=${countryCode}`,
      }
    );

    if (!response.ok) {
      throw new Error('Failed to switch shipping destination');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const GetShippingDestination = () => {
  const { languageRoute, resolvedShippingDestination } = useAppSettingsData();
  const { cart } = GetCart(languageRoute);
  return cart?.shippingDestination ?? resolvedShippingDestination;
};
